export default [
  // PROFILE
  { uri: "api/v1/profile", name: "profile.store", methods: ["POST"] },
  { uri: "api/v1/profile", name: "profile.index", methods: ["GET", "HEAD"] },
  {
    uri: "api/v1/profile/address",
    name: "profile.address",
    methods: ["GET", "HEAD"],
  },
  {
    uri: "api/v1/profile/address/add",
    name: "profile.address.add",
    methods: ["POST"],
  },
  {
    uri: "api/v1/profile/address/remove",
    name: "profile.address.remove",
    methods: ["POST"],
  },
  {
    uri: "api/v1/profile/address/update",
    name: "profile.address.update",
    methods: ["POST"],
  },
  {
    uri: "api/v1/profile/avatar",
    name: "profile.avatar",
    methods: ["GET", "HEAD"],
  },
  {
    uri: "api/v1/profile/{id}",
    name: "profile.destroy",
    methods: ["DELETE"],
  },
  {
    uri: "api/v1/profile/{id}",
    name: "profile.update",
    methods: ["PUT", "PATCH"],
  },
  {
    uri: "api/v1/profile/{id}",
    name: "profile.show",
    methods: ["GET", "HEAD"],
  },
];
