export default [
  {
    path: "*",
    page: "index",
    name: "error",
    meta: {
      sortOrder: 999,
      module: "error",
      title: "error.404",
      drawer: true,
      main: true,
      breadcrumb: "home",
    },
  },
];
