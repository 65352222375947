
import { Component, Prop, Vue } from "vue-property-decorator";

type EBtnVariant = 2 | 3 | 4 | 5 | 6 | 7 | "2" | "3" | "4" | "5" | "6" | "7";
type EBtnTag = "a" | "button" | "div" | "span";

@Component({ name: "e-btn" })
export default class EBtn extends Vue {
  @Prop(Boolean) readonly link!: boolean;

  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly loading!: boolean;
  @Prop(Boolean) readonly outlined!: boolean;
  @Prop(Boolean) readonly icon!: boolean;
  @Prop([String, Number]) readonly variant!: EBtnVariant;
  @Prop({ type: String, default: "button" }) readonly tag!: EBtnTag;

  get sComponent(): EBtnTag {
    return this.link ? "a" : this.tag;
  }

  get css() {
    const sVariant = `e-btn-${this.variant || "none"}`;

    return {
      "e-btn": true,
      [sVariant]: !!this.variant,
      "e-btn-border": this.outlined,
      "e-btn-icon": this.icon,
    };
  }
}
