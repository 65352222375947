/* eslint-disable @typescript-eslint/no-var-requires */
import Vue from "vue";
import router from "@/router";
import VueRouterUserRoles from "vue-router-user-roles";
import { isString } from "lodash";

import { Base } from "@planetadeleste/vue-mc";
import { AuthModule } from "@/store/auth";
import { LoadingModule } from "@/store/loading";
import { FlashModule } from "@/store/flash";

import { route } from "@/services/laroute";

Vue.use(VueRouterUserRoles, { router });

// Set API Model
Base.$resolve = route;
Base.$authModule = AuthModule;
Base.$flashModule = FlashModule;
Base.$loadingModule = LoadingModule;
Base.$http = Vue.axios;

// Set User (this.$user)
const sUser = localStorage.getItem("user");
const user = isString(sUser) ? JSON.parse(sUser) : null;
if (user) {
  Vue.prototype.$user.set(user);
}
