export default [
  {
    path: "/checkout",
    name: "checkout",
    page: "index",
    meta: {
      sortOrder: 7,
      module: "checkout",
      title: "checkout",
      drawer: false,
      main: false,
      breadcrumb: {
        label: "checkout.page.title",
        parent: "home",
      },
    },
  },
];
