import Vue from "vue";
// import { i18n } from "@/plugins/i18n";
import {
  ValidationProvider,
  ValidationObserver,
  //   configure,
  localize,
  extend,
} from "vee-validate";
import es from "vee-validate/dist/locale/es.json";
import { required, email, confirmed, max, min } from "vee-validate/dist/rules";

localize("es", es);

extend("email", email);
extend("required", required);
extend("confirmed", confirmed);
extend("max", max);
extend("min", min);

// Register it globally
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
