export default [
  // CATEGORIES
  {
    uri: "api/v1/categories",
    name: "categories.index",
    methods: ["GET", "HEAD"],
  },
  { uri: "api/v1/categories", name: "categories.store", methods: ["POST"] },
  {
    uri: "api/v1/categories/list",
    name: "categories.list",
    methods: ["GET", "HEAD"],
  },
  {
    uri: "api/v1/categories/tree",
    name: "categories.tree",
    methods: ["GET", "HEAD"],
  },
  {
    uri: "api/v1/categories/{id}",
    name: "categories.update",
    methods: ["PUT", "PATCH"],
  },
  {
    uri: "api/v1/categories/{id}",
    name: "categories.destroy",
    methods: ["DELETE"],
  },
  {
    uri: "api/v1/categories/{id}",
    name: "categories.show",
    methods: ["GET", "HEAD"],
  },
];
