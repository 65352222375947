import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";

export interface LoadingState {
  attach: boolean | string;
  isLoading: boolean;
}

@Module({
  name: "loading",
  dynamic: true,
  namespaced: true,
  store: store,
})
class Loading extends VuexModule implements LoadingState {
  public attach: boolean | string = false;
  public isLoading = false;

  @Mutation
  private setLoadState(loadState: boolean) {
    this.isLoading = loadState;
  }

  @Mutation
  private setAttachElement(element: string | boolean) {
    this.attach = element;
  }

  @Action
  loading(attach?: string) {
    if (attach) {
      this.setAttachElement(attach);
    } else {
      this.setLoadState(true);
    }
  }

  @Action
  loaded(attach?: boolean) {
    if (attach) {
      this.setAttachElement(false);
    } else {
      this.setLoadState(false);
    }
  }

  get getLoading(): boolean {
    return this.isLoading;
  }

  get getLoadingTo(): string | boolean {
    return this.attach;
  }
}

export const LoadingModule = getModule(Loading);
