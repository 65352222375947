export default [
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/learning/students",
    name: "learning.students.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/learning/students",
    name: "learning.students.store",
  },
  {
    methods: ["POST"],
    uri: "api/v1/learning/students/playing",
    name: "learning.students.playing",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/learning/students/{id}",
    name: "learning.students.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/learning/students/{id}",
    name: "learning.students.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/learning/students/{id}",
    name: "learning.students.destroy",
  },
];
