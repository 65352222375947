export default [
  {
    path: "/login",
    page: "index",
    name: "login",
    meta: {
      sortOrder: 100,
      module: "login",
      title: "login",
      drawer: true,
      main: true,
      breadcrumb: "login",
    },
  },
];
