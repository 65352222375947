/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Mixins, VModel, Watch } from "vue-property-decorator";
import GlobalMixin from "@/mixins/GlobalMixin";
import PaginateMixin from "@/mixins/PaginateMixin";
import { Collection, Model as BaseApiModel } from "@planetadeleste/vue-mc";
import { LayoutModule } from "@/store/layout";
import { LoadingModule } from "@/store/loading";
import { Response } from "vue-mc";
import { invoke } from "lodash";

type ConstructorOf<A> = new (...args: any[]) => A;

@Component
export default class ModelMixin<
  A extends BaseApiModel = BaseApiModel,
  B extends Collection = Collection
> extends Mixins(GlobalMixin, PaginateMixin) {
  @VModel({ type: Object, default: () => ({}) }) obModel!: A;

  obModelClass!: ConstructorOf<A>;
  obCollection!: B;
  obItem: A | Record<string, any> = {};
  sRoutePath = "";
  displayForm = false;
  previewItem = false;
  filters: Record<string, any> = {};

  @Watch("previewItem")
  onPreviewItemChange(value: boolean): void {
    if (!value) {
      this.cancel();
    }
  }

  async save(): Promise<void> {
    invoke(this, "onBeforeSave");

    if (!this.obModel) {
      return;
    }

    LoadingModule.loading();
    this.obModel.sync();
    const response = await this.obModel
      .store()
      .then((response) => response?.getData())
      .catch((reason) => {
        this.evalResponse(reason);
      });

    LoadingModule.loaded();

    if (!response) {
      return;
    }

    this.evalResponse(response);

    if (response.status) {
      LayoutModule.setReloadOn();
    }

    this.cancel();
    invoke(this, "saved", [response]);
  }

  onCreated(): void {
    if (!this.obModel || this.$_.isEmpty(this.obModel)) {
      const obModel = this.defaultModel();
      this.$emit("input", obModel);
    }
  }

  defaultModel(): A {
    return new this.obModelClass();
  }

  async cancel(): Promise<void> {
    if (!this.obModelClass) {
      return;
    }

    this.$emit("input", this.defaultModel());

    if (!this.sRoutePath || this.$route.path == this.sRoutePath) {
      return;
    }

    await this.$router.push(this.sRoutePath).catch((error) => {
      if (error.name != "NavigationDuplicated") {
        throw error;
      }
    });
  }

  async index(): Promise<void> {
    if (!this.obCollection) {
      return;
    }

    this.bLocalLoading = true;
    invoke(this, "onBeforeIndex");

    this.obCollection.clear();
    this.obCollection
      .filterBy(this.$_.omit(this.filters, "page"))
      .page(this.currentPage);

    const response = await this.obCollection.fetch();

    if (response) {
      this.mapPagination(response.getData());
    }

    invoke(this, "onAfterIndex");
    this.bLocalLoading = false;
  }

  async deleteItem(iItemId: number): Promise<void> {
    const obModel: A | undefined = this.obCollection.find({ id: iItemId }) as
      | A
      | undefined;
    if (!obModel) {
      return;
    }

    await this.remove(obModel);
  }

  async remove(
    obModel: A,
    sText = "ask.remove.record"
  ): Promise<Response<any> | null> {
    if (!obModel) {
      return null;
    }

    const sMessage = this.$_.toString(this.$t(sText));
    const bRes = await window.confirm(sMessage);
    if (bRes) {
      const obResponse = await obModel.delete();
      this.evalResponse(obResponse?.getData());

      this.deleted();
      return obResponse;
    }

    return null;
  }

  deleted(): void {
    LayoutModule.setReloadOn();
  }

  onUpdateOrCreate(iID: number): void {
    const obModel: A | undefined = iID
      ? (this.obCollection.find({ id: iID }) as A | undefined)
      : this.defaultModel();

    this.obItem = obModel || this.defaultModel();
    this.displayForm = true;
  }
}
