import {
  Module,
  VuexModule,
  Action,
  getModule,
  Mutation,
} from "vuex-module-decorators";
import store from "@/store";
import { Profile } from "@planetadeleste/vue-mc-shopaholic";
import { CourseData } from "@planetadeleste/vue-mc-learning";
import _ from "lodash";
import axios from "axios";
import { EventBus } from "@/services/event-bus";

export interface AuthStatus {
  loggingIn: boolean;
  loggedIn: boolean;
}

const sProfile = localStorage.getItem("user");
const obProfileData = sProfile ? JSON.parse(sProfile) : {};
const obProfile = new Profile(obProfileData);
const initStatus: AuthStatus = {
  loggingIn: false,
  loggedIn: _.has(obProfile, "id"),
};

@Module({
  name: "authentication",
  dynamic: true,
  store: store,
  namespaced: true,
})
class Auth extends VuexModule {
  private _user: Profile = obProfile;
  private _status: AuthStatus = initStatus;
  private _redirect = "";
  private _csrfToken = "";

  @Mutation
  private SET_LOGGING(status: boolean) {
    this._status.loggingIn = status;
  }

  @Mutation
  private SET_LOGGEDIN(status: boolean) {
    this._status.loggedIn = status;
  }

  @Mutation
  private SET_USER(user: Profile) {
    this._user = user;
  }

  @Mutation
  private SET_REDIRECT(path: string) {
    this._redirect = path;
  }

  @Mutation
  private SET_CSRF(token: string) {
    this._csrfToken = token;
  }

  @Action
  loginRequest(user: Profile) {
    this.SET_LOGGING(true);
    this.SET_USER(user);
  }

  @Action
  loginSuccess(user: Profile) {
    this.SET_LOGGING(false);
    this.SET_LOGGEDIN(true);
    this.SET_USER(user);
  }

  @Action
  storeUser(obUserData: Record<string, any>) {
    this._user.set(obUserData);
    this._user.sync();
    const sUser = JSON.stringify(obUserData);
    localStorage.setItem("user", sUser);
  }

  @Action
  loginFailure() {
    this.logout();
  }

  @Action
  logout() {
    this.SET_LOGGING(false);
    this.SET_LOGGEDIN(false);
    this.SET_USER(new Profile());

    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");

    EventBus.emit("logged-out", true);
  }

  @Action
  redirectTo(path: string) {
    this.SET_REDIRECT(path);
  }

  @Action
  async loadCsrf() {
    const response = await axios.get("/auth/csrf");
    if (response.data) {
      this.SET_CSRF(_.get(response.data, "data.token"));
    }
  }

  get isLogged(): boolean {
    return this._status.loggedIn;
  }

  get user(): Profile {
    return this._user;
  }

  get courses(): CourseData[] {
    return this._user.get("courses", []);
  }

  get isAdmin(): boolean {
    return this.isLogged && this.user.role == "admin";
  }

  get isCompany(): boolean {
    return this.isLogged ? this.user.role == "company" : false;
  }

  get isCustomer(): boolean {
    return this.isLogged ? this.user.role == "customer" : false;
  }

  get isStudent(): boolean {
    return (
      this.isLogged &&
      this.user.role == "student" &&
      this.user.groups.includes("student")
    );
  }

  get redirect(): string | null {
    return this._redirect.length ? this._redirect : null;
  }

  get csrfToken() {
    return this._csrfToken;
  }
}

export const AuthModule = getModule(Auth);
