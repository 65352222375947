
import { Component, Mixins, Prop } from "vue-property-decorator";
import CartMixin from "@/modules/cart/mixins/Cart";
import { CartComponentPositionData } from "@planetadeleste/vue-mc-orders";
import {
  OfferData,
  Product,
  ProductCollection,
  ProductData,
} from "@planetadeleste/vue-mc-shopaholic";
import { first } from "lodash";
import { RawLocation } from "vue-router";
import { LayoutModule } from "@/store/layout";

@Component
export default class CartPositionItem extends Mixins(CartMixin) {
  @Prop(Object) readonly cartPosition!: CartComponentPositionData;

  obProduct: Product = new Product();

  get product(): ProductData | null {
    return this.cartPosition && this.$_.has(this.cartPosition, "product.id")
      ? this.cartPosition.product
      : null;
  }

  get offer(): OfferData | null {
    return this.cartPosition && this.$_.has(this.cartPosition, "offer.id")
      ? this.cartPosition.offer
      : null;
  }

  get courseUrl(): RawLocation | null {
    const sSlug = this.obProduct
      ? this.obProduct.slug
      : this.product
      ? this.product.slug
      : null;

    return sSlug ? { name: "courses.view", params: { slug: sSlug } } : null;
  }

  get sUrl(): string {
    return this.courseUrl ? this.$router.resolve(this.courseUrl).href : "#";
  }

  get previewImage(): string | null {
    if (!this.product) {
      return null;
    }

    const obProduct = this.obProduct.id ? this.obProduct : this.product;

    if (
      !obProduct ||
      (!obProduct.preview_image &&
        (!obProduct.images || !obProduct.images.length))
    ) {
      return require("@/assets/images/no-image.png");
    }

    return obProduct.preview_image || this.$_.get(obProduct.images, "0.path");
  }

  async mounted(): Promise<void> {
    await this.load();
  }

  goCourse(): void {
    LayoutModule.closeCartMini();

    if (this.$route.name === "courses.view" || !this.courseUrl) {
      return;
    }

    this.$router.push(this.courseUrl);
  }

  async load(): Promise<void> {
    if (!this.product) {
      return;
    }

    const obController = new ProductCollection();
    obController
      .filterBy({ slug: this.product.slug })
      .fetch()
      .then((obResponse) => {
        const obModelData: ProductData | undefined = first(
          obResponse?.getData().data
        );
        if (obModelData) {
          this.obProduct = new Product(obModelData);
        }
      });
  }

  async removeItem(): Promise<void> {
    if (!this.cart || !this.offer) {
      return;
    }

    await this.removeCart(this.offer.id);
  }
}
