export default [
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/learning/courses",
    name: "learning.courses.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/learning/courses",
    name: "learning.courses.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/learning/courses/{slug}",
    name: "learning.courses.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/learning/courses/{id}",
    name: "learning.courses.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/learning/courses/{id}",
    name: "learning.courses.destroy",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/learning/courses/{id}/video",
    name: "learning.courses.video",
  },
];
