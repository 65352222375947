export default [
  { uri: "api/v1/groups", name: "groups.store", methods: ["POST"] },
  { uri: "api/v1/groups", name: "groups.index", methods: ["GET", "HEAD"] },
  {
    uri: "api/v1/groups/{id}",
    name: "groups.update",
    methods: ["PUT", "PATCH"],
  },
  {
    uri: "api/v1/groups/{id}",
    name: "groups.show",
    methods: ["GET", "HEAD"],
  },
  {
    uri: "api/v1/groups/{id}",
    name: "groups.destroy",
    methods: ["DELETE"],
  },
];
