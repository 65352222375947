
import { Component, Vue } from "vue-property-decorator";
import { LayoutModule } from "@/store/layout";
import { CartModule } from "@/store/cart";
import CartItemList from "@/components/cart/CartItemList.vue";
import { CartComponentItemsData } from "@planetadeleste/vue-mc-orders";

@Component({
  components: { CartItemList },
})
export default class CartMiniArea extends Vue {
  get opened(): boolean {
    return LayoutModule.cartmini;
  }

  get cartItems(): CartComponentItemsData | null {
    return CartModule.items;
  }

  onClose(): void {
    LayoutModule.closeCartMini();
  }

  onGoAndClose(sValue: string): void {
    this.onClose();
    if (this.$route.name !== sValue) {
      this.$router.push({ name: sValue });
    }
  }
}
