import { Component, Mixins, Prop } from "vue-property-decorator";
import ModelMixin from "@/mixins/ModelMixin";
import { VueClass } from "vue-class-component/lib/declarations";
import {
  Cart as CartModel,
  CartComponentAddData,
  CartComponentItemsData,
} from "@planetadeleste/vue-mc-orders";
import { CartModule } from "@/store/cart";
import { FlashModule } from "@/store/flash";
import { ProductData } from "@planetadeleste/vue-mc-shopaholic";
import { find, isUndefined } from "lodash";

const TypedModelMixin = ModelMixin as unknown as VueClass<
  ModelMixin<CartModel>
>;

@Component
export default class Cart extends Mixins(TypedModelMixin) {
  @Prop({ type: Object, default: () => ({}) }) readonly property!: Record<
    string,
    any
  >;
  bLoading = false;

  get cart(): CartModel {
    return CartModule.cart;
  }

  get cartItems(): CartComponentItemsData | null {
    return CartModule.items;
  }

  created(): void {
    this.obModelClass = CartModel;
    this.sRoutePath = "/cart";

    this.onCreated();
  }

  inCart(iOfferID: number): boolean {
    if (!this.cartItems) {
      return false;
    }

    return !isUndefined(
      find(this.cartItems.positions, (obItem) => {
        return obItem.offer && obItem.offer.id === iOfferID;
      })
    );
  }

  async addToCart(
    iOfferID: number,
    iQty: number,
    iShippingTypeId?: number,
    iPaymentMethodId?: number,
    obProduct?: ProductData
  ): Promise<void> {
    if (iQty < 1) {
      iQty = 1;
    }

    const obData: CartComponentAddData = {
      cart: [{ offer_id: iOfferID, quantity: iQty, property: this.property }],
      shipping_type_id: iShippingTypeId,
      payment_method_id: iPaymentMethodId,
    };

    this.bLoading = true;

    const obResponse = await this.cart.add(obData);

    if (obResponse) {
      const obResponseData = obResponse.getData().data;
      CartModule.setData(obResponseData);

      // Add notify message
      // const obMessage: QueueMessage = {
      //   message: this.$t("product.added.to.cart"),
      //   src: obProduct ? obProduct.preview_image : undefined,
      //   icon: "cart",
      //   data: obProduct ? { name: obProduct.name } : undefined
      // };
      FlashModule.info(this.$t("product.added.to.cart"));
    }

    this.bLoading = false;
  }

  async updateCart(obCartData: CartComponentAddData): Promise<void> {
    // const obCartData = this.toCartData();
    this.bLoading = true;
    const obResponse = await this.cart.update(obCartData);
    if (obResponse) {
      const obResponseData = obResponse.getData().data;
      CartModule.setData(obResponseData);
      FlashModule.enqueue({
        message: this.$t("cart.updated"),
        icon: "cart",
        data: this.$_.isEmpty(obResponseData)
          ? undefined
          : {
              name: `${obResponseData.currency} ${obResponseData.total}`,
              title: this.$t("total.label"),
            },
      });
    }
    this.bLoading = false;
  }

  async removeCart(iOfferID: number): Promise<void> {
    this.bLoading = true;
    const obResponse = await this.cart.remove({ cart: [iOfferID] });
    if (obResponse) {
      const obResponseData = obResponse.getData().data;
      CartModule.setData(obResponseData);
      FlashModule.enqueue({
        message: this.$t("cart.updated"),
        icon: "cart",
        data: this.$_.isEmpty(obResponseData)
          ? undefined
          : {
              name: `${obResponseData.currency} ${obResponseData.total}`,
              title: this.$t("total.label"),
            },
      });
    }
    this.bLoading = false;
  }

  toCartData(
    obCartItems: CartComponentItemsData,
    shippingTypeId?: number,
    paymentMethodId?: number
  ): CartComponentAddData {
    const obData: CartComponentAddData = {
      cart: [],
      shipping_type_id: shippingTypeId,
      payment_method_id: paymentMethodId,
    };

    if (obCartItems) {
      this.$_.each(obCartItems.positions, (obPosition) => {
        obData.cart.push({
          offer_id: obPosition.offer.id,
          quantity: obPosition.quantity,
        });
      });
    }

    return obData;
  }
}
