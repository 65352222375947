export default [
  { uri: "api/v1/users", name: "users.index", methods: ["GET", "HEAD"] },
  { uri: "api/v1/users", name: "users.store", methods: ["POST"] },
  {
    uri: "api/v1/users/{id}",
    name: "users.show",
    methods: ["GET", "HEAD"],
  },
  {
    uri: "api/v1/users/{id}",
    name: "users.update",
    methods: ["PUT", "PATCH"],
  },
  { uri: "api/v1/users/{id}", name: "users.destroy", methods: ["DELETE"] },
];
