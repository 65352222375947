/* eslint-disable @typescript-eslint/ban-ts-comment */
import store from "@/store";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import {
  CategoryCollection,
  GroupCollection,
} from "@planetadeleste/vue-mc-shopaholic";
import {
  Countries,
  State,
  States,
  Towns,
} from "@planetadeleste/vue-mc-location";
import { CategoryCollection as BlogCategories } from "@planetadeleste/vue-mc-goodnews";
import { get, has, isString, set } from "lodash";
import { EventBus } from "@/services/event-bus";

type FilterParam = {
  model: string;
  value: Record<string, any>;
  key?: string;
};

@Module({ dynamic: true, store, name: "app", namespaced: true })
class App extends VuexModule {
  private _blog_categories: BlogCategories = new BlogCategories();

  private _categories: CategoryCollection = new CategoryCollection();

  get categories() {
    return this._categories;
  }

  private _groups: GroupCollection = new GroupCollection();

  get groups() {
    return this._groups;
  }

  private _countries: Countries = new Countries();

  get countries() {
    return this._countries;
  }

  private _states: States = new States();

  get states() {
    return this._states;
  }

  private _towns: Towns = new Towns();

  get towns() {
    return this._towns;
  }

  private _filters: Record<string, Record<string, any>> = {};

  get filters() {
    return this._filters;
  }

  get roles(): string[] {
    return this._groups.map("code");
  }

  get defaultCountry() {
    return this._countries.find({ is_default: true });
  }

  get defaultState() {
    return this._states.find({ is_default: true });
  }

  get blogCategories() {
    return this._blog_categories;
  }

  @Action
  async loadCategories() {
    this._categories.clear();
    await this._categories.tree();
  }

  @Action
  async loadGroups() {
    await this._groups.fetch();
  }

  @Action
  async loadCountries() {
    await this._countries.filterBy({ active: 1 }).fetch();
  }

  @Action
  async loadStates(iCountryID?: number) {
    const obCountry = iCountryID
      ? this._countries.find({ id: iCountryID })
      : this.defaultCountry;

    this._states.clear();

    if (obCountry) {
      // @ts-ignore
      if (obCountry.states.length) {
        // @ts-ignore
        this._states.add(obCountry.states);
      } else {
        obCountry.getStates().then((response) => {
          if (response) {
            const obData = response.getData();
            this._states.add(obData.data || obData);
          }
        });
      }
    }
  }

  @Action
  async loadTowns(iStateID?: number) {
    const obState = iStateID
      ? this._states.find({ id: iStateID })
      : this.defaultState;

    if (obState && obState instanceof State) {
      obState.getTowns().then((response) => {
        if (response) {
          const obData = response.getData();
          this._towns.clear();
          this._towns.add(obData.data || obData);
        }
      });
    }
  }

  @Mutation
  setFilter(obFilterData: FilterParam) {
    const obFilters = this._filters;
    set(obFilters, obFilterData.model, obFilterData.value);
    this._filters = obFilters;
    EventBus.emit("filters-change", obFilterData.model);
  }

  @Action({ commit: "setFilter" })
  addFilter(obFilterData: FilterParam) {
    const sContainer = obFilterData.model;
    const sValue = obFilterData.value;
    const sKey = obFilterData.key;
    let obFilters = has(this._filters, sContainer)
      ? get(this._filters, sContainer, {})
      : {};

    if (sValue && isString(sKey)) {
      set(obFilters, sKey, sValue);
    } else {
      obFilters = sValue;
    }

    return {
      model: sContainer,
      value: obFilters,
    };
  }

  @Action
  async loadBlogCategories() {
    await this._blog_categories.fetch();
  }
}

export const AppModule = getModule(App);
