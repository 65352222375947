export default [
  {
    path: "/",
    page: "index",
    name: "home",
    meta: {
      sortOrder: 1,
      module: "home",
      title: "home",
      drawer: true,
      main: true,
      breadcrumb: "home",
    },
  },
];
