import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "@/store";
import VueI18n from "vue-i18n";
import { first, has, isEmpty, isString, toString, uniqueId } from "lodash";
import { TYPE } from "vue-toastification";

export interface FlashObject {
  variant: TYPE;
  message: FlashMessage;
}

export type FlashMessage = string | VueI18n.TranslateResult;

export interface QueueMessage {
  message: FlashMessage;
  id?: number | string;
  data?: Record<string, any>;
  icon?: string;
  src?: string;
  html?: boolean;
}

@Module({
  name: "flash",
  dynamic: true,
  store: store,
})
export default class Flash extends VuexModule {
  public _snack = false;
  private _snackColor: TYPE = TYPE.DEFAULT;
  private _snackText: FlashMessage = "";
  private _messages: QueueMessage[] = [];

  get getSnack(): boolean {
    return this._snack;
  }

  get getSnackColor(): TYPE {
    return this._snackColor;
  }

  get getSnackText(): string {
    return toString(this._snackText);
  }

  get hasQueue(): boolean {
    return !isEmpty(this._messages);
  }

  get queued(): QueueMessage | null | undefined {
    return this.hasQueue ? first(this._messages) : null;
  }

  get flashed(): boolean {
    return !isEmpty(this._snackText);
  }

  get variant(): TYPE {
    return this._snackColor;
  }

  get flashMessage(): FlashMessage {
    return toString(this._snackText);
  }

  @Action
  message(message: FlashObject): void {
    this.SET_FLASH(message);
  }

  @Action
  success(message: FlashMessage): void {
    this.message({ message: message, variant: TYPE.SUCCESS });
  }

  @Action
  error(message: FlashMessage): void {
    this.message({ message: message, variant: TYPE.ERROR });
  }

  @Action
  warning(message: FlashMessage): void {
    this.message({ message: message, variant: TYPE.WARNING });
  }

  @Action
  info(message: FlashMessage): void {
    this.message({ message: message, variant: TYPE.INFO });
  }

  @Action
  closeFlash(): void {
    this.CLEAR_FLASH();
  }

  @Action
  enqueue(message: QueueMessage | string): void {
    if (isString(message)) {
      message = { message, id: uniqueId() };
    }

    if (!has(message, "id") || !message.id) {
      message.id = uniqueId();
    }

    this.ADD_QUEUE(message);
  }

  @Action
  dequeue(): void {
    this.DEL_QUEUE();
  }

  @Mutation
  private SET_FLASH(flash: FlashObject): void {
    this._snackColor = flash.variant || "info";
    this._snackText = flash.message;
    this._snack = true;
  }

  @Mutation
  private CLEAR_FLASH(): void {
    this._snack = false;
    this._snackText = "";
    this._snackColor = TYPE.DEFAULT;
  }

  @Mutation
  private ADD_QUEUE(obMsg: QueueMessage) {
    this._messages.push(obMsg);
  }

  @Mutation
  private DEL_QUEUE() {
    if (isEmpty(this._messages)) {
      return;
    }

    this._messages.splice(0, 1);
  }
}

export const FlashModule = getModule(Flash);
