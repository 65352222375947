export default [
  // PRODUCTS
  { uri: "api/v1/products", name: "products.store", methods: ["POST"] },
  {
    uri: "api/v1/products",
    name: "products.index",
    methods: ["GET", "HEAD"],
  },
  {
    uri: "api/v1/products/list",
    name: "products.list",
    methods: ["GET", "HEAD"],
  },
  {
    uri: "api/v1/products/{id}/offers",
    name: "products.offers",
    methods: ["GET", "HEAD"],
  },
  {
    uri: "api/v1/products/{id}",
    name: "products.destroy",
    methods: ["DELETE"],
  },
  {
    uri: "api/v1/products/{id}",
    name: "products.update",
    methods: ["PUT", "PATCH"],
  },
  {
    uri: "api/v1/products/{id}",
    name: "products.show",
    methods: ["GET", "HEAD"],
  },
];
