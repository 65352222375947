export default [
  {
    path: "/testimonials",
    name: "testimonials",
    page: "index",
    meta: {
      sortOrder: 999,
      module: "testimonials",
      title: "testimonials",
      drawer: true,
      main: true,
      breadcrumb: {
        label: "testimonials",
        parent: "home",
      },
    },
  },
];
