export default [
  // COUNTRIES
  {
    uri: "api/v1/countries",
    name: "countries.index",
    methods: ["GET", "HEAD"],
  },
  { uri: "api/v1/countries", name: "countries.store", methods: ["POST"] },
  {
    uri: "api/v1/countries/{id}",
    name: "countries.destroy",
    methods: ["DELETE"],
  },
  {
    uri: "api/v1/countries/{id}",
    name: "countries.show",
    methods: ["GET", "HEAD"],
  },
  {
    uri: "api/v1/countries/{id}",
    name: "countries.update",
    methods: ["PUT", "PATCH"],
  },
  {
    uri: "api/v1/countries/{id}/states",
    name: "countries.states",
    methods: ["GET", "HEAD"],
  },
];
