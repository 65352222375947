
import { Component, Vue } from "vue-property-decorator";
import { CartModule } from "@/store/cart";
import CartPositionItem from "@/components/cart/CartPositionItem.vue";
import {
  CartComponentItemsData,
  CartComponentPositionData,
} from "@planetadeleste/vue-mc-orders";

@Component({
  components: { CartPositionItem },
})
export default class CartItemList extends Vue {
  get cartItems(): CartComponentItemsData | null {
    return CartModule.items;
  }

  get arPositions(): CartComponentPositionData[] {
    return this.cartItems ? this.cartItems.positions : [];
  }
}
