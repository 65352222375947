
import { Course, CourseCollection } from "@planetadeleste/vue-mc-learning";
import { Component, Vue } from "vue-property-decorator";
import { Location } from "vue-router";

interface MenuItem {
  label: string;
  to?: Location;
  el?: string;
  children?: MenuItem[];
}

@Component
export default class TopNav extends Vue {
  obCourseCollection: CourseCollection = new CourseCollection();
  arMenuItems: MenuItem[] = [
    { label: "home", to: { name: "home" }, el: "main-top" },
    { label: "instructor", to: { name: "instructor" }, el: "instructor" },
    { label: "interviews", to: { name: "interviews" }, el: "interviews" },
    { label: "testimonials", to: { name: "testimonials" }, el: "testimonials" },
    { label: "contact", to: { name: "contact" }, el: "contact" },
  ];

  get mainCourse(): Course | null | undefined {
    return this.obCourseCollection.length === 1
      ? this.obCourseCollection.first()
      : null;
  }

  get mainCourseTo(): Location | null {
    return this.mainCourse && this.mainCourse.id
      ? { name: "courses.view", params: { slug: this.mainCourse.slug } }
      : null;
  }

  get isHome(): boolean {
    return !!this.$route.name && this.$route.name == "home";
  }

  mounted(): void {
    this.$nextTick(() => {
      this.load();
    });
  }

  async load(): Promise<void> {
    this.obCourseCollection.filterBy({ active: 1 });
    await this.obCourseCollection.fetch();

    if (this.obCourseCollection.length) {
      const obMenuItem: MenuItem = {
        label: "courses",
        children: [],
      };
      if (this.mainCourse && this.mainCourseTo) {
        obMenuItem.label = "course";
        obMenuItem.to = this.mainCourseTo;
        obMenuItem.el = "main-course";
      } else {
        obMenuItem.children = this.$_.map(
          this.obCourseCollection.models,
          (obModel) => {
            return {
              label: obModel.name,
              to: {
                name: "courses.view",
                params: { slug: obModel.slug },
              },
            };
          }
        );
      }

      this.arMenuItems.splice(1, 0, obMenuItem);
    }
  }
}
