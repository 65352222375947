export default [
  {
    path: "/cart",
    page: "index",
    name: "cart",
    meta: {
      sortOrder: 7,
      module: "cart",
      title: "cart",
      drawer: true,
      main: true,
      breadcrumb: {
        label: "cart",
        parent: "home",
      },
    },
  },
];
