export default [
  {
    path: "/account",
    page: "index",
    name: "account",
    meta: {
      module: "account",
      title: "my.account",
      drawer: true,
      main: true,
    },
    children: [
      {
        path: "",
        page: "dashboard",
        name: "dashboard",
      },
      {
        path: "courses",
        page: "courses",
        name: "courses",
        children: [
          {
            path: ":key",
            page: "courses",
            name: "view",
          },
        ],
      },
    ],
  },
];
