export default [
  // OFFERS
  { uri: "api/v1/offers", name: "offers.store", methods: ["POST"] },
  {
    uri: "api/v1/offers/{id}",
    name: "offers.destroy",
    methods: ["DELETE"],
  },
  {
    uri: "api/v1/offers/{id}",
    name: "offers.update",
    methods: ["PUT", "PATCH"],
  },
];
