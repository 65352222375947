export default [
  { methods: ["POST"], uri: "api/v1/cart/add", name: "cart.add" },
  { methods: ["GET", "HEAD"], uri: "api/v1/cart/data", name: "cart.data" },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/cart/get/{shipping_type_id?}",
    name: "cart.get",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/cart/payment_method_list",
    name: "cart.payment_method_list",
  },
  { methods: ["POST"], uri: "api/v1/cart/remove", name: "cart.remove" },
  { methods: ["POST"], uri: "api/v1/cart/update", name: "cart.update" },
];
