
import { Component, Ref, Vue } from "vue-property-decorator";
import TopNav from "@/components/common/TopNav.vue";
import Logo from "@/components/common/Logo.vue";
import { LayoutModule } from "@/store/layout";
import BtnUserAccount from "@/modules/account/components/BtnUserAccount.vue";
import IconWhatsapp from "@/components/icons/IconWhatsapp.vue";
import CartBtn from "@/components/cart/CartBtn.vue";

@Component({
  components: { CartBtn, TopNav, Logo, BtnUserAccount, IconWhatsapp },
})
export default class TopBar extends Vue {
  windowTop = 0;

  @Ref("elLogo") readonly obElLogo!: HTMLElement;

  get white(): boolean {
    const arRouteNames = ["home", "contact", "courses.list"];
    return this.inRoute(arRouteNames);
  }

  get shadow(): boolean {
    const arRouteNames = [
      "courses.view",
      "login",
      "register",
      "instructor",
      "testimonials",
      "account.dashboard",
    ];
    return this.inRoute(arRouteNames);
  }

  get sticky() {
    return this.windowTop > 100;
  }

  get css(): Record<string, any> {
    return {
      header__white: this.white,
      header__shadow: this.shadow,
      sticky: this.sticky,
    };
  }

  get logoHeight(): string {
    return this.obElLogo ? `${this.obElLogo.clientHeight}px` : "100px";
  }

  get logoWidth(): string {
    return this.obElLogo ? `${this.obElLogo.clientWidth}px` : "100px";
  }

  mounted(): void {
    window.addEventListener("scroll", this.onScroll);
  }

  beforeDestroy(): void {
    window.removeEventListener("scroll", this.onScroll);
  }

  openSidebar(): void {
    LayoutModule.openSidebar();
  }

  inRoute(arNames: string[]): boolean {
    return !!this.$route.name && arNames.includes(this.$route.name);
  }

  onScroll(): void {
    this.windowTop = window.scrollY;
  }
}
