import "@/registerServiceWorker";
import "@/plugins";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { i18n } from "@/plugins/i18n";
import { VueSvgIcon } from "@yzfe/vue-svgicon";

Vue.config.productionTip = false;
Vue.component("icon", VueSvgIcon);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
