export default [
  {
    path: "/servicios",
    page: "index",
    name: "courses",
    meta: {
      sortOrder: 10,
      module: "courses",
      title: "courses",
      drawer: true,
      main: true,
      breadcrumb: "courses",
    },
    children: [
      {
        path: "",
        page: "list",
        name: "list",
        meta: {
          title: "list.courses",
          breadcrumb: {
            label: "list.courses",
          },
        },
      },
      {
        path: ":slug",
        page: "view",
        name: "view",
        meta: {
          title: "view.course",
          breadcrumb: {
            label: "view.course",
          },
        },
      },
    ],
  },
];
