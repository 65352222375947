export default [
  { uri: "api/v1/towns", name: "towns.store", methods: ["POST"] },
  {
    uri: "api/v1/towns/{id}",
    name: "towns.update",
    methods: ["PUT", "PATCH"],
  },
  {
    uri: "api/v1/towns/{id}",
    name: "towns.show",
    methods: ["GET", "HEAD"],
  },
  { uri: "api/v1/towns/{id}", name: "towns.destroy", methods: ["DELETE"] },
];
