import store from "@/store";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import {
  Cart,
  CartComponentItemsData,
  PaymentMethods,
} from "@planetadeleste/vue-mc-orders";

const obCart = new Cart();
const obPaymentMethods = new PaymentMethods();

@Module({ name: "cart", dynamic: true, store, namespaced: true })
class CartStore extends VuexModule {
  private _data: CartComponentItemsData | null = null;
  private _payment_methods: PaymentMethods = obPaymentMethods;

  private _cart: Cart = obCart;

  /**
   * @description Get Cart model
   * @author Alvaro Canepa <bfpdevel@gmail.com>
   * @readonly
   * @memberof CartStore
   * @returns {Cart}
   */
  get cart(): Cart {
    return this._cart;
  }

  /**
   * @description Get cart items
   * @author Alvaro Canepa <bfpdevel@gmail.com>
   * @readonly
   * @memberof CartStore
   * @returns {CartComponentItemsData | null}
   */
  get items(): CartComponentItemsData | null {
    return this._data && this._data.positions ? this._data : null;
  }

  /**
   * @description Get PaymentMethods collection
   * @author Alvaro Canepa <bfpdevel@gmail.com>
   * @readonly
   * @memberof CartStore
   * @returns {PaymentMethods}
   */
  get paymentMethods(): PaymentMethods {
    return this._payment_methods;
  }

  /**
   * @description Load cart items from server
   * @author Alvaro Canepa <bfpdevel@gmail.com>
   * @memberof CartStore
   */
  @Action
  async loadData() {
    const obResponse = await this._cart.getItems();
    if (obResponse) {
      this.SET_CART_DATA(obResponse.getData().data);
    }
  }

  @Action
  setData(obData: CartComponentItemsData) {
    this.SET_CART_DATA(obData);
  }

  /**
   * @description Load payment methods
   * @author Alvaro Canepa <bfpdevel@gmail.com>
   * @memberof CartStore
   */
  @Action
  async loadPaymentMethods() {
    await this._payment_methods.fetch();
  }

  @Mutation
  private SET_CART_DATA(obData: CartComponentItemsData) {
    this._data = obData;
  }
}

export const CartModule = getModule(CartStore);
