export default [
  {
    methods: ["POST"],
    uri: "api/v1/learning/stripe/payment_intents",
    name: "learning.stripe.payment.intents",
  },
  {
    methods: ["POST"],
    uri: "api/v1/learning/stripe/pk",
    name: "learning.stripe.pk",
  },
];
