export default [
  { uri: "api/v1/files", name: "files.store", methods: ["POST"] },
  {
    uri: "api/v1/files/resize/{disk_name}",
    name: "files.resize",
    methods: ["GET", "HEAD"],
  },
  {
    uri: "api/v1/files/{file}",
    name: "files.update",
    methods: ["PUT", "PATCH"],
  },
  { uri: "api/v1/files/{file}", name: "files.destroy", methods: ["DELETE"] },
];
