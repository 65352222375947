
import { Component, Prop, Vue } from "vue-property-decorator";
import { CartComponentItemsData } from "@planetadeleste/vue-mc-orders";
import IconCart from "@/components/icons/IconCart.vue";
import { LayoutModule } from "@/store/layout";
import { CartModule } from "@/store/cart";

@Component({
  components: { IconCart },
})
export default class CartBtn extends Vue {
  @Prop(Boolean) readonly white!: boolean;

  get cartItems(): CartComponentItemsData | null {
    return CartModule.items;
  }

  get cartLength(): number {
    return this.cartItems ? this.cartItems.positions.length : 0;
  }

  onToggle(): void {
    LayoutModule.toggleCartMini();
  }
}
