export default [
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/paymentmethods",
    name: "paymentmethods.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/paymentmethods",
    name: "paymentmethods.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/paymentmethods/{id}",
    name: "paymentmethods.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/paymentmethods/{id}",
    name: "paymentmethods.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/paymentmethods/{id}",
    name: "paymentmethods.destroy",
  },
];
