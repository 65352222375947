import * as path from "path";
import { kebabCase } from "lodash";
import Vue from "vue";
import frag from "vue-frag";
import SvgIcon from "@bit/planetadeleste.gui.ui.svg-icon";

Vue.directive("frag", frag);
Vue.component("svg-icon", SvgIcon);

// Load bootstrap components
const ComponentContext = require.context(
  "@/components/bs",
  false,
  /\.vue$/i,
  "lazy"
);
ComponentContext.keys().forEach((componentFilePath) => {
  let componentName = path.basename(
    componentFilePath,
    path.extname(componentFilePath)
  );
  componentName = kebabCase(componentName);
  Vue.component(componentName, () => ComponentContext(componentFilePath));
});

// Load icons components
const IconsComponentContext = require.context(
  "@/components/icons",
  false,
  /\.vue$/i,
  "lazy"
);
IconsComponentContext.keys().forEach((componentFilePath) => {
  let componentName = path.basename(
    componentFilePath,
    path.extname(componentFilePath)
  );
  componentName = kebabCase(componentName);
  Vue.component(componentName, () => IconsComponentContext(componentFilePath));
});
