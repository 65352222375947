export default [
  { uri: "api/v1/states", name: "states.store", methods: ["POST"] },
  {
    uri: "api/v1/states/{id}/towns",
    name: "states.towns",
    methods: ["GET", "HEAD"],
  },
  {
    uri: "api/v1/states/{id}",
    name: "states.show",
    methods: ["GET", "HEAD"],
  },
  {
    uri: "api/v1/states/{id}",
    name: "states.destroy",
    methods: ["DELETE"],
  },
  {
    uri: "api/v1/states/{id}",
    name: "states.update",
    methods: ["PUT", "PATCH"],
  },
];
