
import { Component, Vue } from "vue-property-decorator";
import { LayoutModule } from "@/store/layout";

@Component({ name: "body-overlay" })
export default class BodyOverlay extends Vue {
  close(): void {
    LayoutModule.closeSidebar();
    LayoutModule.closeCartMini();
  }

  get opened(): boolean {
    return LayoutModule.sidebar || LayoutModule.cartmini;
  }
}
