export default [
  {
    uri: "api/v1/blog/articles",
    name: "blog.articles.store",
    methods: ["POST"],
  },
  {
    uri: "api/v1/blog/articles",
    name: "blog.articles.index",
    methods: ["GET", "HEAD"],
  },
  {
    uri: "api/v1/blog/articles/{slug}",
    name: "blog.articles.show",
    methods: ["GET", "HEAD"],
  },
  {
    uri: "api/v1/blog/articles/{id}",
    name: "blog.articles.update",
    methods: ["PUT", "PATCH"],
  },
  {
    uri: "api/v1/blog/articles/{id}",
    name: "blog.articles.destroy",
    methods: ["DELETE"],
  },
  {
    uri: "api/v1/blog/categories",
    name: "blog.categories.index",
    methods: ["GET", "HEAD"],
  },
  {
    uri: "api/v1/blog/categories",
    name: "blog.categories.store",
    methods: ["POST"],
  },
  {
    uri: "api/v1/blog/categories/list",
    name: "blog.categories.list",
    methods: ["GET", "HEAD"],
  },
  {
    uri: "api/v1/blog/categories/{id}",
    name: "blog.categories.show",
    methods: ["GET", "HEAD"],
  },
  {
    uri: "api/v1/blog/categories/{id}",
    name: "blog.categories.destroy",
    methods: ["DELETE"],
  },
  {
    uri: "api/v1/blog/categories/{id}",
    name: "blog.categories.update",
    methods: ["PUT", "PATCH"],
  },
];
