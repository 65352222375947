export default [
  { uri: "api/v1/auth/check", name: "auth.check", methods: ["POST"] },
  {
    uri: "api/v1/auth/check_reset_code",
    name: "auth.check_reset_code",
    methods: ["GET", "HEAD"],
  },
  {
    uri: "api/v1/auth/csrf",
    name: "auth.csrf_token",
    methods: ["GET", "HEAD"],
  },
  {
    uri: "api/v1/auth/invalidate",
    name: "auth.invalidate",
    methods: ["POST"],
  },
  { uri: "api/v1/auth/login", name: "auth.login", methods: ["POST"] },
  { uri: "api/v1/auth/refresh", name: "auth.refresh", methods: ["POST"] },
  { uri: "api/v1/auth/register", name: "auth.register", methods: ["POST"] },
  {
    uri: "api/v1/auth/reset_password",
    name: "auth.reset_password",
    methods: ["POST"],
  },
  {
    uri: "api/v1/auth/restore_password",
    name: "auth.restore_password",
    methods: ["POST"],
  },
  { uri: "api/v1/auth/signup", name: "auth.signup", methods: ["POST"] },
];
