// eslint-disable-next-line @typescript-eslint/no-var-requires
const package = require("../../package.json");

module.exports = {
  production: {
    API_ENDPOINT: "/api/v1",
    BASE_URL: "/",
    VERSION: package.version,
    APP_KEY: "2wp9nNU6gfTek3v5ry4c4qp1lfQWuonhFJiSL/2rtNY=",
  },
  development: {
    API_ENDPOINT: "/api/v1",
    BASE_URL: "/",
    APP_KEY: "Jg0PTfkGclDT2Q+yneV993BzGJsi/+PcFNq7Pu6yM5M=",
  },
};
