import Vue from "vue";
import VueRouter, { Route } from "vue-router";
import routes from "./routes";
import { i18n, loadLanguageAsync } from "@/plugins/i18n";
import { BASEURL } from "./base";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: BASEURL,
  routes,
});

// Set translations
router.beforeEach(async (to: Route, from: Route, next: CallableFunction) => {
  window.scrollTo(0, 0);

  const lang = to.params.lang || i18n.locale;
  if (lang) {
    await loadLanguageAsync(lang);
  }
  next();
});

export default router;
