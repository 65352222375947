import { RouteConfig, RedirectOption, NavigationGuard } from "vue-router";
import {
  PathToRegexpOptions,
  Component,
  RoutePropsFunction,
} from "vue-router/types/router";
import _ from "lodash";

export interface RouteConfigLocal {
  page?: string;
  path: string;
  name?: string;
  children?: RouteConfig[];
  redirect?: RedirectOption;
  alias?: string | string[];
  meta?: Record<string, any>;
  beforeEnter?: NavigationGuard;
  caseSensitive?: boolean;
  pathToRegexpOptions?: PathToRegexpOptions;
  component?: Component;
  props?: boolean | Record<string, any> | RoutePropsFunction;
}

const hasDefaultChild = (route: RouteConfigLocal) => {
  if (!_.has(route, "children") || _.isEmpty(route.children)) {
    return false;
  }

  const obNestRoute = _.chain(route.children)
    .filter((obRoute: RouteConfigLocal) => obRoute.path == "")
    .first()
    .value();

  return !obNestRoute || !_.isEmpty(obNestRoute.children)
    ? false
    : _.has(obNestRoute, "page");
};

/**
 * Map route
 * @author Alvaro Canepa <bfpdevel@gmail.com>
 * @param {RouteConfigLocal} route
 * @param {string} [sModuleName]
 * @param {RouteConfigLocal} [parentRoute]
 * @return {*}
 */
const mapRoute = (
  route: RouteConfigLocal,
  sModuleName?: string,
  parentRoute?: RouteConfigLocal
) => {
  const hasChild = _.isArray(route.children);
  const hasChildDefault = hasDefaultChild(route);
  // const isMain = _.get(route, "meta.main", false);
  const sModule = _.get(route, "meta.module", sModuleName);
  const sComponentPath = `modules/${sModule}/views`;
  const sView = _.chain(route.page).camelCase().upperFirst().value();
  let sName = route.name;

  // Route is child
  if (parentRoute) {
    // if (!route.path || _.isEmpty(route.path) || route.path == "") {
    //   route.path = parentRoute.path;
    // }

    // Construct child route name
    const arRouteParts: string[] = [];

    if (parentRoute.name) {
      arRouteParts.push(parentRoute.name);
    } else {
      arRouteParts.push(_.kebabCase(parentRoute.path));
    }

    if (!sName) {
      sName = _.kebabCase(route.path);
    }

    arRouteParts.push(sName);
    sName = _.join(arRouteParts, "-");
  } else if (route.path) {
    if (!sName) {
      sName = _.kebabCase(route.path);
    }
  }

  if (sName) {
    sName = _.replace(sName, "-", ".");
  }

  route.name = sName;

  if (hasChild) {
    route.children = _.map(route.children, (r) => mapRoute(r, sModule, route));
  }

  route.component = () => import(`@/${sComponentPath}/${sView}.vue`);

  if (hasChildDefault) {
    _.unset(route, "name");
  }

  return route;
};

let baseRoutes: RouteConfigLocal[] = [];

const req = require.context("@/", true, /modules\/([^/]+\/)router\/index\.ts/);
req.keys().forEach((key) => {
  const obModule = req(key);
  baseRoutes = _.concat(baseRoutes, obModule.default);
});
baseRoutes = _.sortBy(baseRoutes, (r) => {
  return _.get(r, "meta.sortOrder", 99);
});

const routes = _.map(baseRoutes, (r) => mapRoute(r));

export default routes;
