
import { Vue, Component } from "vue-property-decorator";
import Logo from "@/components/common/Logo.vue";

@Component({
  components: { Logo },
})
export default class Footer extends Vue {
  get copyright(): string {
    return this.$moment().format("YYYY");
  }
}
