export default [
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/learning/sections",
    name: "learning.sections.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/learning/sections",
    name: "learning.sections.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/learning/sections/{id}",
    name: "learning.sections.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/learning/sections/{id}",
    name: "learning.sections.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/learning/sections/{id}",
    name: "learning.sections.destroy",
  },
];
