import { Component, Mixins, Prop, Vue } from "vue-property-decorator";
import GlobalMixin from "@/mixins/GlobalMixin";
import { AuthModule } from "@/store/auth";
import {
  Auth as AuthModel,
  Profile,
  ResponseLoginRegisterData,
  UserData,
  UserRegisterOptions,
} from "@planetadeleste/vue-mc-shopaholic";
import VueI18n from "vue-i18n";
import { EventBus } from "@/services/event-bus";
import {
  CourseCollection,
  CourseData,
  Student,
} from "@planetadeleste/vue-mc-learning";
import { Location } from "vue-router";
import { chain, delay, get, has } from "lodash";

@Component
export default class AuthMixin extends Mixins(GlobalMixin) {
  @Prop(Boolean) readonly preventRedirect!: boolean;

  get user(): Profile {
    return AuthModule.user;
  }

  get logged(): boolean {
    return AuthModule.isLogged;
  }

  get student(): Student {
    const obStudentData = this.user.get("student", {});
    return obStudentData instanceof Student
      ? obStudentData
      : new Student(obStudentData);
  }

  get courses(): CourseData[] {
    return AuthModule.courses;
  }

  get userInitials(): string | null {
    return !this.user
      ? null
      : chain(this.fullName)
          .trim()
          .words()
          .filter((word) => word.length > 0)
          .map((word) => word.substring(0, 1))
          .join("")
          .toUpper()
          .value();
  }

  get fullName(): string {
    return this.user ? `${this.user.name} ${this.user.last_name}` : "";
  }

  /**
   * Enter to the platform by user login auth
   * @param login
   * @param password
   */
  async logIn(
    login: string,
    password: string
  ): Promise<ResponseLoginRegisterData | boolean> {
    // Reset auth store vars
    AuthModule.logout();

    const response = await this.user
      .login(login, password)
      .then((res) => res.getData());

    if (!response || !response.status) {
      let sError: string | null | VueI18n.TranslateResult = get(
        response,
        "message"
      );

      if (!sError || sError === "invalid_credentials") {
        sError = await this.$t("invalid.credentials");
      }

      this.flashError(sError);
      return false;
    }

    // Validate user group permissions (only for students)
    const obUserData: UserData = get(response, "data.user");

    if (!obUserData || !obUserData.groups.includes("student")) {
      const sMessage = await this.$t("invalid.permissions");
      await this.logOut();
      this.flashError(sMessage);

      return false;
    }

    return this.auth(response.data);
  }

  async register(
    params: UserRegisterOptions
  ): Promise<undefined | ResponseLoginRegisterData> {
    const response = await this.user.register(params);

    if (!response || has(response, "error")) {
      let msg = "Ha habido un problema al registrar tu cuenta";
      msg = get(response, "error", msg);
      this.flashError(msg);
      return undefined;
    }

    const obData = response.getData();

    if (!obData.status) {
      if (obData.message) {
        this.flashError(obData.message);
      }

      return undefined;
    }

    return await this.auth(obData.data);
  }

  /**
   * Exit from platform and clear user login data
   */
  async logOut(): Promise<void> {
    if (!localStorage.getItem("access_token")) {
      return;
    }

    try {
      await this.user.logout();
      AuthModule.logout();
    } catch (e) {
      console.error(e.message);
    } finally {
      this.clearLogin();
    }
  }

  /**
   * Clear user login data
   */
  clearLogin(): void {
    EventBus.emit("logout.success");
    if (!this.preventRedirect && this.$route.name !== "home") {
      this.$router.push({ name: "home" });
    }
  }

  /**
   * Collect login data and set app token
   * @param data
   */
  async auth(
    data: ResponseLoginRegisterData
  ): Promise<ResponseLoginRegisterData> {
    if (data.token && data.expires_in && this.user) {
      localStorage.setItem("access_token", data.token);
      localStorage.setItem("refresh_token", data.token);

      const tokensExpiry = this.$moment()
        .add(data.expires_in, "s")
        .toISOString();
      localStorage.setItem("expires_in", tokensExpiry);

      if (data.user) {
        this.user.set(data.user);
        const sUser = JSON.stringify(data.user);
        localStorage.setItem("user", sUser);
        Vue.prototype.$user.set(this.user);

        AuthModule.loginSuccess(this.user);
        await this.initSession();
      }
    }

    return data;
  }

  initSession(): Promise<void> {
    return new Promise(() => {
      if (!this.logged) {
        return;
      }

      const tokenExpiryDate = localStorage.getItem("expires_in");
      if (!tokenExpiryDate) {
        return this.logOut();
      }

      const beforeExpiry = this.$moment(tokenExpiryDate).subtract(10, "m");

      if (this.$moment().isAfter(beforeExpiry)) {
        return this.logOut();
      }

      delay(this.refreshAccessToken, beforeExpiry.diff(this.$moment()));

      this.sessionCheck();
      EventBus.emit("login.success");

      // Prevent redirect on checkout page
      if (this.preventRedirect) {
        return;
      }

      const obRouteData: Location = { name: "account.dashboard" };

      if (this.courses.length) {
        const obCourse = this.courses[0];
        if (obCourse) {
          obRouteData.name = "courses.view";
          obRouteData.params = { slug: obCourse.slug };
        }
      }

      this.$router.push(obRouteData);
    });
  }

  /**
   * Get new access token
   */
  async refreshAccessToken(): Promise<string | undefined> {
    const obAuthModel = new AuthModel();
    const response = await obAuthModel
      .refresh()
      .then((response) => response.getData());

    if (!response || !response.status) {
      await this.logOut();
      return;
    }

    if (has(response, "data.token")) {
      await this.auth(response.data);

      const tokenExpiryDate = localStorage.getItem("expires_in");
      if (!tokenExpiryDate) {
        return;
      }

      const tenMinutesBeforeExpiry = this.$moment(tokenExpiryDate).subtract(
        10,
        "m"
      );

      delay(
        this.refreshAccessToken,
        tenMinutesBeforeExpiry.diff(this.$moment())
      );
    }

    return response.data.token;
  }

  async reloadUserAvatar(): Promise<void> {
    if (!this.user || !this.logged) {
      throw "No se ha iniciado sesión";
    }
    await this.user.loadAvatar();
  }

  /**
   * Reload student courses
   */
  async reloadCourses(): Promise<void> {
    if (!this.student || !this.logged) {
      return;
    }

    const obCourseCollection = new CourseCollection();
    await obCourseCollection.filterBy({ student: this.student.id }).fetch();
    this.user.set("courses", obCourseCollection.getModelList());
  }

  /**
   * @description Check session auth every 20 minutes.
   * In case of returns false or error will be to logout
   * @author Alvaro Canepa <bfpdevel@gmail.com>
   * @return {void}
   * @memberof Auth
   */
  sessionCheck(): void {
    if (!this.logged) {
      return;
    }

    const nextAuthCheck = this.$moment().add(20, "m");

    delay(() => {
      this.authCheck().then((response) => {
        if (!response) {
          this.logOut();
        } else {
          this.sessionCheck();
        }
      });
    }, nextAuthCheck.diff(this.$moment()));
  }

  /**
   * @description Check if user is logged on api
   * @author Alvaro Canepa <bfpdevel@gmail.com>
   * @return {*}  {Promise<boolean>}
   * @memberof Auth
   */
  async authCheck(): Promise<boolean> {
    let bLogged = false;

    try {
      const obAuthModel = new AuthModel();
      const response = await obAuthModel
        .silenty()
        .check()
        .then((response) => response.getData());
      bLogged = get(response, "status", false);
    } catch (error) {
      return bLogged;
    }

    return bLogged;
  }
}
