
import { Component, Vue } from "vue-property-decorator";
import TopNav from "@/components/common/TopNav.vue";
import Logo from "@/components/common/Logo.vue";
import { LayoutModule } from "@/store/layout";

@Component({
  components: { TopNav, Logo },
})
export default class SidebarNav extends Vue {
  get opened(): boolean {
    return LayoutModule.sidebar;
  }

  close(): void {
    LayoutModule.closeSidebar();
  }
}
