
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Logo extends Vue {
  @Prop({ type: String, default: "70%" }) readonly size!: string;
  @Prop(String) readonly width!: string;
  @Prop(String) readonly height!: string;

  sLogoWhite: string = require("@image/logo-white.svg");
  sLogoColor: string = require("@image/logo.svg");

  get cssStyle() {
    return {
      width: this.width || this.size,
      height: this.height || this.size,
    };
  }
}
