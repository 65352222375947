export default [
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/learning/reviews",
    name: "learning.reviews.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/learning/reviews",
    name: "learning.reviews.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/learning/reviews/{id}",
    name: "learning.reviews.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/learning/reviews/{id}",
    name: "learning.reviews.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/learning/reviews/{id}",
    name: "learning.reviews.destroy",
  },
];
