export default [
  // BRANDS
  { uri: "api/v1/brands", name: "brands.store", methods: ["POST"] },
  { uri: "api/v1/brands", name: "brands.index", methods: ["GET", "HEAD"] },
  {
    uri: "api/v1/brands/{id}",
    name: "brands.destroy",
    methods: ["DELETE"],
  },
  {
    uri: "api/v1/brands/{id}",
    name: "brands.update",
    methods: ["PUT", "PATCH"],
  },
  {
    uri: "api/v1/brands/{id}",
    name: "brands.show",
    methods: ["GET", "HEAD"],
  },
];
