/* eslint-disable @typescript-eslint/no-empty-function */
import { Vue, Component, Watch } from "vue-property-decorator";
import { ApiMetaResponse } from "@bit/planetadeleste.shopaholic.types.api";
import { Result } from "vue-mc";

type DataOptions = {
  page: number;
  itemsPerPage: number;
  sortBy: string[];
  sortDesc: boolean[];
  groupBy: string[];
  groupDesc: boolean[];
  multiSort: boolean;
  mustSort: boolean;
};

@Component
export default class PaginateMixin extends Vue {
  pagination: DataOptions = {
    page: 1,
    itemsPerPage: 15,
    sortBy: [],
    sortDesc: [false],
    groupBy: [],
    groupDesc: [false],
    multiSort: false,
    mustSort: false,
  };
  // filters: object = {};
  bLocalLoading = false;
  serverItemsLength = 0;
  serverLastPage = 0;
  currentPage = 1;

  @Watch("pagination", { deep: true })
  onPagination(dataNew: DataOptions, dataOld: DataOptions) {
    if (dataNew.page != this.currentPage) {
      this.paginate(dataNew.page);
      return;
    }

    if (
      this.$_.has(dataNew, "sortBy") &&
      this.$_.has(dataNew, "sortDesc") &&
      !this.bLocalLoading
    ) {
      const newSortBy = this.$_.first(dataNew.sortBy),
        oldSortBy = this.$_.first(dataOld.sortBy),
        newSortDesc = this.$_.first(dataNew.sortDesc),
        oldSortDesc = this.$_.first(dataOld.sortDesc);

      if (newSortBy !== oldSortBy || newSortDesc !== oldSortDesc) {
        this.index();
      }
    }
  }

  // @Watch("reload")
  // onReload(value: boolean) {
  //   if (value) {
  //     LayoutModule.setReloadOff();
  //     this.index();
  //   }
  // }

  mapPagination(data: Result | ApiMetaResponse) {
    const paginationData: ApiMetaResponse = this.$_.has(data, "meta")
      ? this.$_.get(data, "meta")
      : data;

    this.pagination = this.$_.assign(this.pagination, {
      page: paginationData.current_page,
      itemsPerPage: paginationData.per_page,
      sortBy: this.pagination.sortBy,
      sortDesc: this.pagination.sortDesc,
    });
    this.serverItemsLength = paginationData.total;
    this.serverLastPage = paginationData.last_page;
  }

  // get reload() {
  //   return LayoutModule.reload;
  // }

  index() {}
  paginate(page: number | string) {
    this.currentPage = Number(page);
    this.index();
  }
}
