
import { Vue, Component, Prop } from "vue-property-decorator";
import { LoadingModule } from "@/store/loading";

@Component({ name: "loader" })
export default class Loader extends Vue {
  @Prop(Boolean) readonly local!: boolean;
  @Prop(Boolean) readonly loading!: boolean;

  get isLoading(): boolean {
    return this.local ? this.loading : LoadingModule.getLoading;
  }
}
