import { LarouteConfig } from "@/services/laroute";

const config: LarouteConfig = {
  absolute: false,
  rootUrl: "https://gw.app",
  prefix: "",
  routes: [],
};

export default config;
