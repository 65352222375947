import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import router from "@/router";
import {
  get,
  has,
  indexOf,
  isObject,
  isUndefined,
  set,
  startsWith,
} from "lodash";
import { AuthModule } from "@/store/auth";
import { LayoutModule } from "@/store/layout";
import { FlashModule } from "@/store/flash";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const config = require("@/config");
const ENV = process.env.NODE_ENV;
const { API_ENDPOINT } = ENV ? get(config, ENV) : "";

axios.defaults.baseURL = API_ENDPOINT;
set(axios.defaults.headers.common, "Access-Control-Allow-Origin", "*");

axios.interceptors.request.use(
  (reqConfig) => {
    if (!reqConfig || isUndefined(reqConfig.url)) {
      return reqConfig;
    }

    // clean the url
    if (startsWith(reqConfig.url, API_ENDPOINT)) {
      const url = reqConfig.url.replace(API_ENDPOINT, "");
      set(reqConfig, "url", url);
    }

    // Add Headers
    if (startsWith(reqConfig.baseURL, API_ENDPOINT)) {
      // Add Bearer auth
      if (localStorage.getItem("access_token")) {
        set(
          reqConfig.headers.common,
          "Authorization",
          `Bearer ${localStorage.getItem("access_token")}`
        );
      }

      // Add refresh token
      if (reqConfig.url.includes("/auth/logout")) {
        set(
          reqConfig.headers,
          "X-REFRESH-TOKEN",
          localStorage.getItem("refresh_token")
        );
      }

      // Add CSRF TOKEN
      set(reqConfig.headers, "X-CSRF-TOKEN", AuthModule.csrfToken);
    }

    return reqConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function forceLogout() {
  if (
    router.currentRoute.name &&
    !["login", "register", "checkout"].includes(router.currentRoute.name)
  ) {
    router.push({ name: "login" });
  }

  AuthModule.logout();
  // LayoutModule.setLayout("login");
}

axios.interceptors.response.use(
  (reqConfig) => {
    return reqConfig;
  },

  (err) => {
    const data = get(err, "response.data");

    if (isObject(err.response.config)) {
      const url = get(err, "response.config.url");

      if (
        url.includes("/login") ||
        url.includes("/refresh") ||
        url.includes("/invalidate")
      ) {
        if (data) {
          if (has(data, "error") || has(data, "message")) {
            FlashModule.error(data.error || data.message);
            err = data;
          }
        }

        return Promise.reject(err);
      }
    }

    if (indexOf([400, 401], err.response.status) !== -1) {
      forceLogout();
    }

    if (data) {
      if (has(data, "error") || has(data, "message")) {
        FlashModule.error(data.error || data.message);
        err = data;
      }
    }

    return Promise.reject(err);
  }
);

Vue.use(VueAxios, axios);
