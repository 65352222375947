export default [
  {
    path: "/instructor",
    page: "index",
    name: "instructor",
    meta: {
      sortOrder: 30,
      module: "instructor",
      title: "instructor",
      drawer: true,
      main: true,
      breadcrumb: "instructor",
    },
  },
];
