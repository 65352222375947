export default [
  {
    path: "/register",
    page: "index",
    name: "register",
    meta: {
      sortOrder: 7,
      module: "register",
      title: "register",
      drawer: true,
      main: true,
    },
  },
];
