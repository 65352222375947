import Vue from "vue";
import VueMediaQuery from "vue-media-q";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
Vue.use(VueMediaQuery, {
  xs: 576,
  sm: 768,
  md: 992,
  lg: Infinity,
});
