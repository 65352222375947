
import AuthMixin from "@/mixins/AuthMixin";
import { TranslateResult } from "vue-i18n";
import { Component, Mixins } from "vue-property-decorator";
import { Location } from "vue-router";
import EBtn from "@/components/common/EBtn.vue";

@Component({
  components: { EBtn },
})
export default class BtnUserAccount extends Mixins(AuthMixin) {
  get routeTo(): Location {
    const sName = this.logged ? "account.dashboard" : "login";
    return { name: sName };
  }

  get label(): TranslateResult {
    const sLabel: string = this.logged ? "my.account" : "course.login";
    return this.$t(sLabel);
  }

  get css(): Record<string, boolean> {
    return {
      "e-btn": this.$vmq.sm,
      "link-btn": this.$vmq.xs,
    };
  }
}
