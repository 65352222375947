/* eslint-disable @typescript-eslint/ban-ts-comment */
import { chain, filter } from "lodash";
import { AuthModule } from "@/store/auth";

export const dotcase = (sValue: string): string => {
  return chain(sValue).trim().kebabCase().replace(/-/g, ".").value();
};

export const toCode = (sValue: string): string => {
  return chain(sValue)
    .trim()
    .words()
    .filter((sVal) => sVal.length > 2)
    .map((sVal, skey, arItems) => {
      if (arItems.length > 3) {
        return sVal.charAt(0);
      }
      return sVal.slice(0, 3);
    })
    .join("")
    .toUpper()
    .value();
};

export const getImageSize = (url: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    // @ts-ignore
    image.onload = () => resolve({ width: image.width, height: image.height });
    image.onerror = reject;
    image.src = url;
  });
};

export const isSubscribed = (iCourseId: number): boolean => {
  if (!AuthModule.isLogged || !AuthModule.courses.length) {
    return false;
  }

  return (
    filter(AuthModule.courses, (obCourse) => obCourse.id === iCourseId).length >
    0
  );
};

export const diffPercent = (fValOne: number, fValTwo: number): number => {
  const fSubPart = (fValOne + fValOne) / 2;
  const fPart = (fValOne - fValTwo) / fSubPart;

  return Math.round(fPart * 100);
};
