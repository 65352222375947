
import { Mixins, Component } from "vue-property-decorator";
import Loader from "@/components/common/Loader.vue";
import GoTop from "@/components/common/GoTop.vue";
import FooterMain from "@/components/common/Footer.vue";
import TopBar from "@/components/common/TopBar.vue";
import SidebarNav from "@/components/common/SidebarNav.vue";
import { AppModule } from "@/store/app";
import AuthMixin from "@/mixins/AuthMixin";
import { AuthModule } from "@/store/auth";
import { LoadingModule } from "@/store/loading";
import CartMiniArea from "@/components/cart/CartMiniArea.vue";
import BodyOverlay from "@/components/common/BodyOverlay.vue";
import { CartModule } from "@/store/cart";

@Component({
  components: {
    BodyOverlay,
    Loader,
    GoTop,
    FooterMain,
    TopBar,
    SidebarNav,
    CartMiniArea,
  },
})
export default class LayoutMain extends Mixins(AuthMixin) {
  bDone = false;

  mounted(): void {
    this.$nextTick(this.onPreload);
  }

  async onPreload(): Promise<void> {
    LoadingModule.loading();
    const logged = await this.authCheck();

    if (!logged) {
      AuthModule.logout();
    }

    await AppModule.loadBlogCategories();
    this.bDone = true;
    LoadingModule.loaded();
    await CartModule.loadData();
    await this.reloadCourses();
  }
}
