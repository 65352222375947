export default [
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/learning/lessons",
    name: "learning.lessons.index",
  },
  {
    methods: ["POST"],
    uri: "api/v1/learning/lessons",
    name: "learning.lessons.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/learning/lessons/{id}",
    name: "learning.lessons.show",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/learning/lessons/{id}/{type}",
    name: "learning.lessons.cloud",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/learning/lessons/{id}",
    name: "learning.lessons.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/learning/lessons/{id}",
    name: "learning.lessons.destroy",
  },
];
