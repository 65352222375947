export default [
  { methods: ["POST"], uri: "api/v1/orders", name: "orders.store" },
  { methods: ["GET", "HEAD"], uri: "api/v1/orders", name: "orders.index" },
  { methods: ["POST"], uri: "api/v1/orders/create", name: "orders.create" },
  {
    methods: ["GET", "HEAD", "POST", "PUT", "PATCH", "DELETE", "OPTIONS"],
    uri: "api/v1/orders/ipn",
    name: "orders.ipn",
  },
  {
    methods: ["POST"],
    uri: "api/v1/orders/positions",
    name: "orders.positions.store",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/orders/positions/{id}",
    name: "orders.positions.destroy",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/orders/positions/{id}",
    name: "orders.positions.update",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/orders/stats",
    name: "orders.stats",
  },
  {
    methods: ["POST"],
    uri: "api/v1/orders/status",
    name: "orders.status.store",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/orders/status",
    name: "orders.status.index",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/orders/status/{id}",
    name: "orders.status.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/orders/status/{id}",
    name: "orders.status.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/orders/status/{id}",
    name: "orders.status.destroy",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/orders/{id}/position",
    name: "orders.position",
  },
  { methods: ["GET", "HEAD"], uri: "api/v1/orders/{id}", name: "orders.show" },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/orders/{id}",
    name: "orders.update",
  },
  { methods: ["DELETE"], uri: "api/v1/orders/{id}", name: "orders.destroy" },
];
