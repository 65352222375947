export default [
  // - Currencies
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/currencies",
    name: "currencies.index",
  },
  { methods: ["POST"], uri: "api/v1/currencies", name: "currencies.store" },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/currencies/list",
    name: "currencies.list",
  },
  {
    methods: ["GET", "HEAD"],
    uri: "api/v1/currencies/{id}",
    name: "currencies.show",
  },
  {
    methods: ["PUT", "PATCH"],
    uri: "api/v1/currencies/{id}",
    name: "currencies.update",
  },
  {
    methods: ["DELETE"],
    uri: "api/v1/currencies/{id}",
    name: "currencies.destroy",
  },
];
